import React, {useState, useEffect} from 'react'
import {DataGrid} from '@mui/x-data-grid'
import axios from 'axios'
// import {Button} from '@mui/material'
import {Params} from 'react-router-dom'
import {convertISODate, convertISOTime, GetCurrentUser} from '../../../utils/DefaultFunctions'
import {API} from '../../../utils/services'
import {style} from '@mui/system'
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import './style.css'
import {STRINGS} from '../../../utils/Enums'
import {RemainingDays} from '../../pages/CFC/Slices/CFC.slice'
import CardFromBranch from '../../pages/CFC/CFCScreen/forms/CardFromBranch'
import CardHold from '../../pages/CFC/CFCScreen/forms/CardHold'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import {Dropdown} from 'antd'
import Typography from '@mui/material/Typography'
import Popper from '@mui/material/Popper'
import PopupState, {bindToggle, bindPopper} from 'material-ui-popup-state'
import Fade from '@mui/material/Fade'
import CardActive from '../../pages/CFC/CFCScreen/forms/ActiveModal'
import CardSendForApproval from '../../pages/CFC/CFCScreen/forms/CardSendForApproval'

// { id: 1, col1: 'Hello', title: 'World', subject:'Physics', author:'Mark Twain', edition: '16th', language: 'English', bookType: 'Physical', publishingYear: '2016', publisher: 'Oxford' },

const TablesBranch = ({
  data,
  type,
  transitInType,
  PageSize,
  PageNumber,
  pageNumber,
  pageSize,
  totalRecords,
  tableType,
  // currentTabData
}) => {
  // const downloadAttachments = (attachments) => {
  //   if (!attachments || attachments.length === 0) {
  //     console.error('No attachments to download')
  //     return
  //   }

  //   attachments.forEach((attachment, index) => {
  //     setTimeout(() => {
  //       fetch(attachment.url)
  //         .then((response) => {
  //           if (!response.ok) {
  //             throw new Error(`Failed to fetch ${attachment.url}`)
  //           }
  //           return response.blob()
  //         })
  //         .then((blob) => {
  //           const url = window.URL.createObjectURL(blob)
  //           const link = document.createElement('a')
  //           link.href = url
  //           link.download = attachment.url.split('/').pop() // Use the filename from the URL
  //           link.click()
  //           window.URL.revokeObjectURL(url)
  //         })
  //         .catch((error) => {
  //           console.error('Error downloading attachment:', error)
  //         })
  //     }, index * 1000) // 1000 ms delay between each download
  //   })
  // }

  const downloadAttachments = (attachments) => {
    if (!attachments || attachments.length === 0) {
      console.error('No attachments to download')
      return
    }

    attachments.forEach((attachment, index) => {
      setTimeout(() => {
        const link = document.createElement('a')
        link.href = attachment.url
        link.download = ''
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }, index * 1000) // 1000 ms delay between each download
    })
  }

  console.log(`DATAA ${tableType}===========`)
  let current_id = new URL(window.location).searchParams.get('id')

  const [forceActions, setForceActions] = useState()
  const [cardId, setCardId] = useState({})
  const [nextActivityId, setNextActivityId] = useState('')
  const [showHold, setShowHold] = useState(false)
  const [showHold2, setShowHold2] = useState(false)
  const [showHoldApproval, setShowHoldApproval] = useState(false)
  const [showCardFromBranch, setShowCardFromBranch] = useState(true)
  const [decryptNumberPrint, setDecryptNumberPrint] = useState('')
  const [courierCompanyName, setCourierCompanyName] = useState('')

  // const decryptNumber = (id) => {
  //   API.get(`/Card/decryptNumber?id=${id}`, {
  //     headers: {
  //       Authorization: `Bearer ${user.api_token}`,
  //     },
  //   }).then((res) => {
  //     setDecryptNumberPrint(res.data.result)
  //   }).catch((err) => {
  //     console.log(err, 'err')
  //   })
  // }

  let dispatch = useDispatch()

  let user = GetCurrentUser()

  const ForceActionFunction = (activityId) => {
    // console.log(activityId)
    // if(forceActions==null){
    tableType == 'cfc'
      ? API.post(
          `/ForceAction/getforceactions`,
          {
            activityId: activityId,
            forCFC: true,
          },
          {
            headers: {
              Authorization: `Bearer ${user.api_token}`,
            },
          }
        ).then((res) => {
          setForceActions(res.data.result.$values)
        })
      : API.post(
          `/ForceAction/getforceactions`,
          {
            activityId: activityId,
            forBranch: true,
          },
          {
            headers: {
              Authorization: `Bearer ${user.api_token}`,
            },
          }
        )
          .then((res) => {
            setForceActions(res.data.result.$values)
          })
          .catch((err) => {
            console.log(err, 'err')
          })
    // }
  }
  //COLUMNS
  const transitInColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'remainingDaysTransit', headerName: 'Remaining Days', width: 80},
    {field: 'aging', headerName: 'Aging', width: 80},
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      renderCell: (params) => {
        return (
          <>
            <td>
              <div className='btn-group'>
                {tableType == 'branch' ? (
                  <a
                    className='btn btn-light btn-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_new_card'
                    onClick={() => {
                      console.log('iuo', params.row);
                      setCardId(params.row)
                      setCourierCompanyName(params.row.courierCompany)
                      setShowHold(true)
                    }}
                  >
                    {tableType == 'cfc'
                      ? 'Card From Branch'
                      : params.row.capturedBranch
                      ? 'Card From Branch'
                      : 'Card From CFC'}
                  </a>
                ) : (
                  <a
                    className='btn btn-light btn-sm'
                    data-bs-toggle=''
                    data-bs-target='#'
                    onClick={() => {
                      // setCardId(params.row)
                      // !tableType=="checker"  && setShowHold(true)
                    }}
                  >
                    {tableType == 'cfc'
                      ? 'Card From Branch'
                      : params.row.capturedBranch
                      ? 'Card From Branch'
                      : 'Card From CFC'}
                  </a>
                )}{' '}
              </div>
            </td>
          </>
        )
      },
    },
    {field: 'processStatus', headerName: 'Process Status', width: 180},

    {
      field: 'captureCardStatus',
      headerName: 'Card Status',
      width: 150,
      renderCell: (params) => {
        const status = params.value
        let badgeClass

        if (status === 'Warm') {
          badgeClass = 'badge-warning'
        } else if (status !== 'Active') {
          badgeClass = 'badge-danger'
        } else {
          badgeClass = 'badge-success'
        }

        return (
          <td>
            <span className={`badge ${badgeClass}`}>{status}</span>
          </td>
        )
      },
    },

    {field: 'cardExpiry', headerName: 'Expiry', width: 180},
    {
      field: 'cardNumber',
      headerName: 'card Number',
      width: 180,
      renderCell: (params) => {
        return (
          <p
            // onMouseLeave={() => {
            //   setDecryptNumberPrint('')
            // }}
            // onMouseEnter={() => decryptNumber(params.row.id)}
            // class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'black',
              fontSize: '13px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            // title={decryptNumberPrint}
            aria-hidden='true'
          >
            {params.row.cardNumber}{' '}
          </p>
        )
      },
    },
    {field: 'customerName', headerName: 'customer Name', width: 180},
    {field: 'acquirerBank', headerName: 'Acquirer Bank', width: 180},
    {field: 'acquirerBranch', headerName: 'Captured Branch', width: 180},
    {field: 'issuingBank', headerName: 'Issuing Bank', width: 180},
    {field: 'issuingBranch', headerName: 'Issuing Branch', width: 180},
    {field: 'captureDate', headerName: 'Capture Date', width: 180},
    {field: 'captureTime', headerName: 'Capture Time', width: 180},
    {field: 'reasonForCaptured', headerName: 'Reason For Captured', width: 180},
    {field: 'captureCardChannel', headerName: 'Card Type', width: 180},
    {field: 'cifNumber', headerName: 'CIF Number', width: 180},
    {
      field: 'timeline',
      headerName: 'Timeline',
      width: 100,
      renderCell: (params) => {
        console.log(params, 'parmss')
        return (
          <Link
            to={`${STRINGS.ROUTES.ADMINISTRATOR.CARDS_TIMELINE}/${params.id}`}
            className='btn btn-primary btn-sm'
            state={{data: params.row}}
          >
            Timeline
          </Link>
        )
      },
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 100,
      renderCell: (params) => {
        const notes = params.value
        return (
          <i
            class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'white',
              fontSize: '24px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            title={notes}
            aria-hidden='true'
          />
        )
      },
    },
  ]
  const dispatchColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'remainingDays', headerName: 'Remaining Days', width: 80},
    {field: 'aging', headerName: 'Aging', width: 80},
    {
      field: 'action',
      headerName: 'Action',
      width: 240,
      renderCell: (params) => {
        return (
          <>
            {tableType != 'cfcDefault' ? (
              !params.row.onApproval ? (
                params.row.action.includes('CFC') || params.row.action.includes('Organization') ? (
                  <td>
                    <div className='btn-group'>
                      <Link
                        to={`${STRINGS.ROUTES.BRANCH.SENDTOCFCFORM}/${params.row.id}`}
                        type='button'
                        className='btn btn-light btn-sm'
                      >
                        {params.row.action}
                      </Link>
                      <PopupState variant='popper' popupId='demo-popup-popper'>
                        {(popupState) => (
                          <div onClick={() => ForceActionFunction(params.row.activityId)}>
                            <button
                              className='btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split'
                              {...bindToggle(popupState)}
                            ></button>
                            <Popper {...bindPopper(popupState)} transition>
                              {({TransitionProps}) => (
                                <Fade {...TransitionProps} timeout={350}>
                                  <Paper style={{padding: '5px'}}>
                                    {forceActions &&
                                      forceActions.map((nestedItem, index) => {
                                        return (
                                          <div
                                            data-bs-toggle='modal'
                                            data-bs-target='#cardActive3'
                                            onClick={() => {
                                              setCardId(params.row)
                                              setNextActivityId(nestedItem.forceActivityId)
                                              setShowHoldApproval(true)
                                            }}
                                            className='dropdown-item'
                                          >
                                            {nestedItem.forceActivity}
                                          </div>
                                        )
                                      })}
                                  </Paper>
                                </Fade>
                              )}
                            </Popper>
                          </div>
                        )}
                      </PopupState>
                    </div>
                  </td>
                ) : (
                  <td>
                    <div className='btn-group'>
                      <Link
                        to={`${STRINGS.ROUTES.BRANCH.SENDTOBRANCHFORM}/${params.row.id}`}
                        type='button'
                        className='btn btn-light btn-sm'
                      >
                        {params.row.action}
                      </Link>
                      <PopupState variant='popper' popupId='demo-popup-popper'>
                        {(popupState) => (
                          <div onClick={() => ForceActionFunction(params.row.activityId)}>
                            <button
                              className='btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split'
                              {...bindToggle(popupState)}
                            ></button>
                            <Popper {...bindPopper(popupState)} transition>
                              {({TransitionProps}) => (
                                <Fade {...TransitionProps} timeout={350}>
                                  <Paper style={{padding: '5px'}}>
                                    {forceActions &&
                                      forceActions.map((nestedItem, index) => {
                                        return (
                                          <div
                                            data-bs-toggle='modal'
                                            data-bs-target='#cardActive3'
                                            onClick={() => {
                                              setCardId(params.row)
                                              setNextActivityId(nestedItem.forceActivityId)
                                              setShowHoldApproval(true)
                                            }}
                                            className='dropdown-item'
                                          >
                                            {nestedItem.forceActivity}
                                          </div>
                                        )
                                        // return nestedItem.forceActivity.includes('Deliver') ? (
                                        //   <Link
                                        //     to={`${STRINGS.ROUTES.BRANCH.DELIVERYFORM}/${params.row.id}`}
                                        //     className='dropdown-item'
                                        //   >
                                        //     {nestedItem.forceActivity}
                                        //   </Link>
                                        // ) : nestedItem.forceActivity.includes('Hold') ? (
                                        //   <button
                                        //     data-bs-toggle='modal'
                                        //     data-bs-target='#exampleModal'
                                        //     className='dropdown-item'
                                        //     onClick={() => {
                                        //       // dispatch(ResetState())
                                        //       setCardId(params.row)
                                        //       setShowHold(true)
                                        //     }}
                                        //   >
                                        //     {nestedItem.forceActivity}
                                        //   </button>
                                        // ) : nestedItem.forceActivity.includes('CFC') ? (
                                        //   <Link
                                        //     to={`${STRINGS.ROUTES.BRANCH.SENDTOCFCFORM}/${params.row.id}`}
                                        //     className='dropdown-item'
                                        //   >
                                        //     {nestedItem.forceActivity}
                                        //   </Link>
                                        // ) : nestedItem.forceActivity.includes('Branch') ? (
                                        //   <Link
                                        //     to={`${STRINGS.ROUTES.BRANCH.SENDTOBRANCHFORM}/${params.row.id}`}
                                        //     className='dropdown-item'
                                        //   >
                                        //     {nestedItem.forceActivity}
                                        //   </Link>
                                        // ) : nestedItem.forceActivity.includes('Shred') ? (
                                        //   <Link
                                        //     onClick={() => dispatch(RemainingDays(params.row.aging))}
                                        //     to={`${STRINGS.ROUTES.BRANCH.SHREDFORM}/${params.row.id}`}
                                        //     className='dropdown-item'
                                        //   >
                                        //     {nestedItem.forceActivity}
                                        //   </Link>
                                        // ) : (
                                        //   <></>
                                        // )
                                      })}
                                  </Paper>
                                </Fade>
                              )}
                            </Popper>
                          </div>
                        )}
                      </PopupState>
                    </div>
                  </td>
                )
              ) : (
                <td>
                  <div className='btn-group'>
                    <div type='button' className='btn btn-light btn-sm'>
                      {params.row.action}
                    </div>
                  </div>
                </td>
              )
            ) : (
              <td>
                <div className='btn-group'>
                  <div type='button' className='btn btn-light btn-sm'>
                    {params.row.action}
                  </div>
                </div>
              </td>
            )}
          </>
        )
      },
    },
    {field: 'processStatus', headerName: 'Process Status', width: 180},

    {
      field: 'captureCardStatus',
      headerName: 'Card Status',
      width: 150,
      renderCell: (params) => {
        const status = params.value
        let badgeClass

        if (status === 'Warm') {
          badgeClass = 'badge-warning'
        } else if (status !== 'Active') {
          badgeClass = 'badge-danger'
        } else {
          badgeClass = 'badge-success'
        }

        return (
          <td>
            <span className={`badge ${badgeClass}`}>{status}</span>
          </td>
        )
      },
    },

    {field: 'cardExpiry', headerName: 'Expiry', width: 180},
    {
      field: 'cardNumber',
      headerName: 'card Number',
      width: 180,
      renderCell: (params) => {
        return (
          <p
            // onMouseLeave={() => {
            //   setDecryptNumberPrint('')
            // }}
            // onMouseEnter={() => decryptNumber(params.row.id)}
            // class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'black',
              fontSize: '13px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            // title={decryptNumberPrint}
            aria-hidden='true'
          >
            {params.row.cardNumber}{' '}
          </p>
        )
      },
    },
    {field: 'customerName', headerName: 'customer Name', width: 180},
    {field: 'acquirerBank', headerName: 'Acquirer Bank', width: 180},
    {field: 'acquirerBranch', headerName: 'Captured Branch', width: 180},
    {field: 'issuingBank', headerName: 'Issuing Bank', width: 180},
    {field: 'issuingBranch', headerName: 'Issuing Branch', width: 180},
    {field: 'captureDate', headerName: 'Capture Date', width: 180},
    {field: 'captureTime', headerName: 'Capture Time', width: 180},
    {field: 'reasonForCaptured', headerName: 'Reason For Captured', width: 180},
    {field: 'captureCardChannel', headerName: 'Card Type', width: 180},
    {field: 'cifNumber', headerName: 'CIF Number', width: 180},
    {
      field: 'timeline',
      headerName: 'Timeline',
      width: 100,
      renderCell: (params) => {
        console.log(params, 'parmss')
        return (
          <Link
            to={`${STRINGS.ROUTES.ADMINISTRATOR.CARDS_TIMELINE}/${params.id}`}
            className='btn btn-primary btn-sm'
            state={{data: params.row}}
          >
            Timeline
          </Link>
        )
      },
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 100,
      renderCell: (params) => {
        const notes = params.value
        return (
          <i
            class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'white',
              fontSize: '24px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            title={notes}
            aria-hidden='true'
          />
        )
      },
    },
  ]
  const deliveryColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'remainingDays', headerName: 'Remaining Days', width: 80},
    {field: 'aging', headerName: 'Aging', width: 80},
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      renderCell: (params) => {
        // console.log(params)
        return (
          <>
            <td>
              {tableType != 'cfcDefault' ? (
                !params.row.onApproval ? (
                  <div className='btn-group'>
                    {tableType == 'checker' ? (
                      <div type='button' className='btn btn-light btn-sm'>
                        {params.row.action}
                      </div>
                    ) : (
                      <>
                        <Link
                          to={`${STRINGS.ROUTES.BRANCH.DELIVERYFORM}/${params.row.id}`}
                          type='button'
                          className='btn btn-light btn-sm'
                        >
                          {params.row.action}
                        </Link>
                        <PopupState variant='popper' popupId='demo-popup-popper'>
                          {(popupState) => (
                            <div onClick={() => ForceActionFunction(params.row.activityId)}>
                              <button
                                className='btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split'
                                {...bindToggle(popupState)}
                              ></button>
                              <Popper {...bindPopper(popupState)} transition>
                                {({TransitionProps}) => (
                                  <Fade {...TransitionProps} timeout={350}>
                                    <Paper style={{padding: '5px'}}>
                                      {forceActions &&
                                        forceActions.map((nestedItem, index) => {
                                          return (
                                            <div
                                              data-bs-toggle='modal'
                                              data-bs-target='#cardActive3'
                                              onClick={() => {
                                                setCardId(params.row)
                                                setNextActivityId(nestedItem.forceActivityId)

                                                setShowHoldApproval(true)
                                              }}
                                              className='dropdown-item'
                                            >
                                              {nestedItem.forceActivity}
                                            </div>
                                          )
                                          // return nestedItem.forceActivity.includes('Deliver') ? (
                                          //   <Link
                                          //     to={`${STRINGS.ROUTES.BRANCH.DELIVERYFORM}/${params.row.id}`}
                                          //     className='dropdown-item'
                                          //   >
                                          //     {nestedItem.forceActivity}
                                          //   </Link>
                                          // ) : nestedItem.forceActivity.includes('Hold') ? (
                                          //   <button
                                          //     data-bs-toggle='modal'
                                          //     data-bs-target='#exampleModal'
                                          //     className='dropdown-item'
                                          //     onClick={() => {
                                          //       // dispatch(ResetState())
                                          //       setCardId(params.row)
                                          //       setShowHold(true)
                                          //     }}
                                          //   >
                                          //     {nestedItem.forceActivity}
                                          //   </button>
                                          // ) : nestedItem.forceActivity.includes('CFC') ? (
                                          //   <Link
                                          //     to={`${STRINGS.ROUTES.BRANCH.SENDTOCFCFORM}/${params.row.id}`}
                                          //     className='dropdown-item'
                                          //   >
                                          //     {nestedItem.forceActivity}
                                          //   </Link>
                                          // ) : nestedItem.forceActivity.includes('Branch') ? (
                                          //   <Link
                                          //     to={`${STRINGS.ROUTES.BRANCH.SENDTOBRANCHFORM}/${params.row.id}`}
                                          //     className='dropdown-item'
                                          //   >
                                          //     {nestedItem.forceActivity}
                                          //   </Link>
                                          // ) : nestedItem.forceActivity.includes('Shred') ? (
                                          //   <Link
                                          //     onClick={() => dispatch(RemainingDays(params.row.aging))}
                                          //     to={`${STRINGS.ROUTES.BRANCH.SHREDFORM}/${params.row.id}`}
                                          //     className='dropdown-item'
                                          //   >
                                          //     {nestedItem.forceActivity}
                                          //   </Link>
                                          // ) : (
                                          //   <></>
                                          // )
                                        })}
                                    </Paper>
                                  </Fade>
                                )}
                              </Popper>
                            </div>
                          )}
                        </PopupState>
                      </>
                    )}
                  </div>
                ) : (
                  <div className='btn-group'>
                    <div type='button' className='btn btn-light btn-sm'>
                      {params.row.action}
                    </div>
                  </div>
                )
              ) : (
                <div className='btn-group'>
                  <div type='button' className='btn btn-light btn-sm'>
                    {params.row.action}
                  </div>
                </div>
              )}
            </td>
          </>
        )
      },
    },
    {field: 'processStatus', headerName: 'Process Status', width: 180},

    {
      field: 'captureCardStatus',
      headerName: 'Card Status',
      width: 150,
      renderCell: (params) => {
        const status = params.value
        let badgeClass

        if (status === 'Warm') {
          badgeClass = 'badge-warning'
        } else if (status !== 'Active') {
          badgeClass = 'badge-danger'
        } else {
          badgeClass = 'badge-success'
        }

        return (
          <td>
            <span className={`badge ${badgeClass}`}>{status}</span>
          </td>
        )
      },
    },

    {field: 'cardExpiry', headerName: 'Expiry', width: 180},
    {
      field: 'cardNumber',
      headerName: 'card Number',
      width: 180,
      renderCell: (params) => {
        // console.log(params)
        return (
          <p
            // onMouseLeave={() => {
            //   // console.log('1', decryptNumberPrint)
            //   setDecryptNumberPrint('')
            // }}
            // onMouseEnter={() => decryptNumber(params.row.id)}
            // class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'black',
              fontSize: '13px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            // title={decryptNumberPrint}
            aria-hidden='true'
          >
            {params.row.cardNumber}{' '}
          </p>
        )
      },
    },
    {field: 'customerName', headerName: 'customer Name', width: 180},
    {field: 'acquirerBank', headerName: 'Acquirer Bank', width: 180},
    {field: 'acquirerBranch', headerName: 'Captured Branch', width: 180},
    {field: 'issuingBank', headerName: 'Issuing Bank', width: 180},
    {field: 'issuingBranch', headerName: 'Issuing Branch', width: 180},
    {field: 'captureDate', headerName: 'Capture Date', width: 180},
    {field: 'captureTime', headerName: 'Capture Time', width: 180},
    {field: 'reasonForCaptured', headerName: 'Reason For Captured', width: 180},
    {field: 'captureCardChannel', headerName: 'Card Type', width: 180},
    {field: 'cifNumber', headerName: 'CIF Number', width: 180},
    {
      field: 'timeline',
      headerName: 'Timeline',
      width: 100,
      renderCell: (params) => {
        console.log(params, 'parmss')
        return (
          <Link
            to={`${STRINGS.ROUTES.ADMINISTRATOR.CARDS_TIMELINE}/${params.id}`}
            className='btn btn-primary btn-sm'
            state={{data: params.row}}
          >
            Timeline
          </Link>
        )
      },
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 100,
      renderCell: (params) => {
        const notes = params.value
        return (
          <i
            class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'white',
              fontSize: '24px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            title={notes}
            aria-hidden='true'
          />
        )
      },
    },
  ]
  const holdColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'remainingDays', headerName: 'Remaining Days', width: 80},
    {field: 'aging', headerName: 'Aging', width: 80},
    {
      field: 'action',
      headerName: 'Action',
      width: 230,
      renderCell: (params) => {
        // console.log(params)
        return (
          <>
            <td>
              {tableType != 'cfcDefault' ? (
                !params.row.onApproval ? (
                  <div className='btn-group'>
                    {tableType == 'checker' ? (
                      <div type='button' className='btn btn-light btn-sm'>
                        {params.row.action}
                      </div>
                    ) : (
                      <>
                        {params.row.action.includes('Shred') ? (
                          <Link
                            to={`${STRINGS.ROUTES.BRANCH.SHREDFORM}/${params.row.id}`}
                            type='button'
                            className='btn btn-light btn-sm'
                          >
                            {params.row.action}
                          </Link>
                        ) : params.row.action.includes('Deliver') ? (
                          <Link
                            to={`${STRINGS.ROUTES.BRANCH.DELIVERYFORM}/${params.row.id}`}
                            type='button'
                            className='btn btn-light btn-sm'
                          >
                            {params.row.action}
                          </Link>
                        ) : (
                          <Link
                            to={`${STRINGS.ROUTES.BRANCH.SENDTOCFCFORM}/${params.row.id}`}
                            type='button'
                            className='btn btn-light btn-sm'
                          >
                            {params.row.action}
                          </Link>
                        )}
                        <PopupState variant='popper' popupId='demo-popup-popper'>
                          {(popupState) => (
                            <div onClick={() => ForceActionFunction(params.row.activityId)}>
                              <button
                                className='btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split'
                                {...bindToggle(popupState)}
                              ></button>
                              <Popper {...bindPopper(popupState)} transition>
                                {({TransitionProps}) => (
                                  <Fade {...TransitionProps} timeout={350}>
                                    <Paper style={{padding: '5px'}}>
                                      {forceActions &&
                                        forceActions.map((nestedItem, index) => {
                                          return (
                                            <div
                                              data-bs-toggle='modal'
                                              data-bs-target='#cardActive3'
                                              onClick={() => {
                                                setCardId(params.row)
                                                setNextActivityId(nestedItem.forceActivityId)

                                                setShowHoldApproval(true)
                                              }}
                                              className='dropdown-item'
                                            >
                                              {nestedItem.forceActivity}
                                            </div>
                                          )
                                        })}
                                    </Paper>
                                  </Fade>
                                )}
                              </Popper>
                            </div>
                          )}
                        </PopupState>
                      </>
                    )}
                  </div>
                ) : (
                  <div className='btn-group'>
                    <div type='button' className='btn btn-light btn-sm'>
                      {params.row.action}
                    </div>
                  </div>
                )
              ) : (
                <div className='btn-group'>
                  <div type='button' className='btn btn-light btn-sm'>
                    {params.row.action}
                  </div>
                </div>
              )}
            </td>
          </>
        )
      },
    },
    {field: 'processStatus', headerName: 'Process Status', width: 180},

    {
      field: 'captureCardStatus',
      headerName: 'Card Status',
      width: 150,
      renderCell: (params) => {
        const status = params.value
        let badgeClass

        if (status === 'Warm') {
          badgeClass = 'badge-warning'
        } else if (status !== 'Active') {
          badgeClass = 'badge-danger'
        } else {
          badgeClass = 'badge-success'
        }

        return (
          <td>
            <span className={`badge ${badgeClass}`}>{status}</span>
          </td>
        )
      },
    },

    {field: 'cardExpiry', headerName: 'Expiry', width: 180},
    {
      field: 'cardNumber',
      headerName: 'card Number',
      width: 180,
      renderCell: (params) => {
        // console.log(params)
        return (
          <p
            // onMouseLeave={() => {
            //   // console.log('1', decryptNumberPrint)
            //   setDecryptNumberPrint('')
            // }}
            // onMouseEnter={() => decryptNumber(params.row.id)}
            // class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'black',
              fontSize: '13px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            // title={decryptNumberPrint}
            aria-hidden='true'
          >
            {params.row.cardNumber}{' '}
          </p>
        )
      },
    },
    {field: 'customerName', headerName: 'customer Name', width: 180},
    {field: 'acquirerBank', headerName: 'Acquirer Bank', width: 180},
    {field: 'acquirerBranch', headerName: 'Captured Branch', width: 180},
    {field: 'issuingBank', headerName: 'Issuing Bank', width: 180},
    {field: 'issuingBranch', headerName: 'Issuing Branch', width: 180},
    {field: 'captureDate', headerName: 'Capture Date', width: 180},
    {field: 'captureTime', headerName: 'Capture Time', width: 180},
    {field: 'reasonForCaptured', headerName: 'Reason For Captured', width: 180},
    {field: 'captureCardChannel', headerName: 'Card Type', width: 180},
    {field: 'cifNumber', headerName: 'CIF Number', width: 180},
    {
      field: 'timeline',
      headerName: 'Timeline',
      width: 100,
      renderCell: (params) => {
        console.log(params, 'parmss')
        return (
          <Link
            to={`${STRINGS.ROUTES.ADMINISTRATOR.CARDS_TIMELINE}/${params.id}`}
            className='btn btn-primary btn-sm'
            state={{data: params.row}}
          >
            Timeline
          </Link>
        )
      },
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 100,
      renderCell: (params) => {
        const notes = params.value
        return (
          <i
            class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'white',
              fontSize: '24px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            title={notes}
            aria-hidden='true'
          />
        )
      },
    },
  ]

  const transitOutColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'remainingDaysTransit', headerName: 'Remaining Days', width: 80},
    {field: 'aging', headerName: 'Aging', width: 80},
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => {
        return (
          <>
            {/* <td>
              <a target='_blank' className='btn btn-light btn-sm'>
                Contact{' '}
                <span>
                  <b>"Call Center"</b>
                </span>
              </a>
            </td> */}
          </>
        )
      },
    },
    {field: 'processStatus', headerName: 'Process Status', width: 180},

    {
      field: 'captureCardStatus',
      headerName: 'Card Status',
      width: 150,
      renderCell: (params) => {
        const status = params.value
        let badgeClass

        if (status === 'Warm') {
          badgeClass = 'badge-warning'
        } else if (status !== 'Active') {
          badgeClass = 'badge-danger'
        } else {
          badgeClass = 'badge-success'
        }

        return (
          <td>
            <span className={`badge ${badgeClass}`}>{status}</span>
          </td>
        )
      },
    },

    {field: 'cardExpiry', headerName: 'Expiry', width: 180},
    {
      field: 'cardNumber',
      headerName: 'card Number',
      width: 180,
      renderCell: (params) => {
        // console.log(params)
        return (
          <p
            // onMouseLeave={() => {
            //   // console.log('1', decryptNumberPrint)
            //   setDecryptNumberPrint('')
            // }}
            // onMouseEnter={() => decryptNumber(params.row.id)}
            // class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'black',
              fontSize: '13px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            // title={decryptNumberPrint}
            aria-hidden='true'
          >
            {params.row.cardNumber}{' '}
          </p>
        )
      },
    },
    {field: 'customerName', headerName: 'customer Name', width: 180},
    {field: 'acquirerBank', headerName: 'Acquirer Bank', width: 180},
    {field: 'acquirerBranch', headerName: 'Captured Branch', width: 180},
    {field: 'issuingBank', headerName: 'Issuing Bank', width: 180},
    {field: 'issuingBranch', headerName: 'Issuing Branch', width: 180},
    {field: 'captureDate', headerName: 'Capture Date', width: 180},
    {field: 'captureTime', headerName: 'Capture Time', width: 180},
    {field: 'reasonForCaptured', headerName: 'Reason For Captured', width: 180},
    {field: 'captureCardChannel', headerName: 'Card Type', width: 180},
    {field: 'cifNumber', headerName: 'CIF Number', width: 180},
    {
      field: 'timeline',
      headerName: 'Timeline',
      width: 100,
      renderCell: (params) => {
        console.log(params, 'parmss')
        return (
          <Link
            to={`${STRINGS.ROUTES.ADMINISTRATOR.CARDS_TIMELINE}/${params.id}`}
            className='btn btn-primary btn-sm'
            state={{data: params.row}}
          >
            Timeline
          </Link>
        )
      },
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 100,
      renderCell: (params) => {
        const notes = params.value
        return (
          <i
            class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'white',
              fontSize: '24px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            title={notes}
            aria-hidden='true'
          />
        )
      },
    },
  ]
  const forApprovalColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'remainingDays', headerName: 'Remaining Days', width: 80},
    {field: 'aging', headerName: 'Aging', width: 80},
    {
      field: 'action',
      headerName: 'Action',
      width: 220,
      renderCell: (params) => {
        return (
          <>
            {params.row.onHold ? (
              <button className='btn btn-secondary btn-sm' disabled>
                Hold
              </button>
            ) : (
              <button className='btn btn-secondary btn-sm' disabled>
                {params.row.action}
              </button>
            )}
          </>
        )
      },
    },
    {field: 'processStatus', headerName: 'Process Status', width: 180},

    {
      field: 'captureCardStatus',
      headerName: 'Card Status',
      width: 150,
      renderCell: (params) => {
        const status = params.value
        let badgeClass

        if (status === 'Warm') {
          badgeClass = 'badge-warning'
        } else if (status !== 'Active') {
          badgeClass = 'badge-danger'
        } else {
          badgeClass = 'badge-success'
        }

        return (
          <td>
            <span className={`badge ${badgeClass}`}>{status}</span>
          </td>
        )
      },
    },

    {field: 'cardExpiry', headerName: 'Expiry', width: 180},
    {
      field: 'cardNumber',
      headerName: 'card Number',
      width: 180,
      renderCell: (params) => {
        // console.log(params)
        return (
          <p
            // onMouseLeave={() => {
            //   // console.log('1', decryptNumberPrint)
            //   setDecryptNumberPrint('')
            // }}
            // onMouseEnter={() => decryptNumber(params.row.id)}
            // class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'black',
              fontSize: '13px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            // title={decryptNumberPrint}
            aria-hidden='true'
          >
            {params.row.cardNumber}{' '}
          </p>
        )
      },
    },
    {field: 'customerName', headerName: 'customer Name', width: 180},
    {field: 'acquirerBank', headerName: 'Acquirer Bank', width: 180},
    {field: 'acquirerBranch', headerName: 'Captured Branch', width: 180},
    {field: 'issuingBank', headerName: 'Issuing Bank', width: 180},
    {field: 'issuingBranch', headerName: 'Issuing Branch', width: 180},
    {field: 'captureDate', headerName: 'Capture Date', width: 180},
    {field: 'captureTime', headerName: 'Capture Time', width: 180},
    {field: 'reasonForCaptured', headerName: 'Reason For Captured', width: 180},
    {field: 'captureCardChannel', headerName: 'Card Type', width: 180},
    {field: 'cifNumber', headerName: 'CIF Number', width: 180},
    {
      field: 'timeline',
      headerName: 'Timeline',
      width: 100,
      renderCell: (params) => {
        console.log(params, 'parmss')
        return (
          <Link
            to={`${STRINGS.ROUTES.ADMINISTRATOR.CARDS_TIMELINE}/${params.id}`}
            className='btn btn-primary btn-sm'
            state={{data: params.row}}
          >
            Timeline
          </Link>
        )
      },
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 100,
      renderCell: (params) => {
        const notes = params.value
        return (
          <i
            class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'white',
              fontSize: '24px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            title={notes}
            aria-hidden='true'
          />
        )
      },
    },
  ]
  const suspiciousColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'remainingDays', headerName: 'Remaining Days', width: 80},
    {field: 'aging', headerName: 'Aging', width: 80},
    {
      field: 'action',
      headerName: 'Action',
      width: 220,
      renderCell: (params) => {
        return (
          <>
            {tableType != 'cfc' && tableType != 'checker' && tableType != 'management' ? (
              <td>
                <Link
                  to={`${STRINGS.ROUTES.BRANCH.SENDTOCFCFORM}/${params.row.id}`}
                  className='btn btn-secondary btn-sm'
                >
                  {params.row.action}
                </Link>
              </td>
            ) : tableType == 'checker' || tableType == 'management' ? (
              <div className='btn btn-secondary btn-sm'>{params.row.action}</div>
            ) : (
              <td>
                <Link
                  to={`${STRINGS.ROUTES.BRANCH.SHREDFORM}/${params.row.id}`}
                  className='btn btn-secondary btn-sm'
                >
                  {params.row.action}
                </Link>
              </td>
            )}
          </>
        )
      },
    },
    {field: 'processStatus', headerName: 'Process Status', width: 180},

    {
      field: 'captureCardStatus',
      headerName: 'Card Status',
      width: 150,
      renderCell: (params) => {
        const status = params.value
        let badgeClass

        if (status === 'Warm') {
          badgeClass = 'badge-warning'
        } else if (status !== 'Active') {
          badgeClass = 'badge-danger'
        } else {
          badgeClass = 'badge-success'
        }

        return (
          <td>
            <span className={`badge ${badgeClass}`}>{status}</span>
          </td>
        )
      },
    },

    {field: 'cardExpiry', headerName: 'Expiry', width: 180},
    {
      field: 'cardNumber',
      headerName: 'card Number',
      width: 180,
      renderCell: (params) => {
        // console.log(params)
        return (
          <p
            // onMouseLeave={() => {
            //   // console.log('1', decryptNumberPrint)
            //   setDecryptNumberPrint('')
            // }}
            // onMouseEnter={() => decryptNumber(params.row.id)}
            // class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'black',
              fontSize: '13px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            // title={decryptNumberPrint}
            aria-hidden='true'
          >
            {params.row.cardNumber}{' '}
          </p>
        )
      },
    },
    {field: 'customerName', headerName: 'customer Name', width: 180},
    {field: 'acquirerBank', headerName: 'Acquirer Bank', width: 180},
    {field: 'acquirerBranch', headerName: 'Captured Branch', width: 180},
    {field: 'issuingBank', headerName: 'Issuing Bank', width: 180},
    {field: 'issuingBranch', headerName: 'Issuing Branch', width: 180},
    {field: 'captureDate', headerName: 'Capture Date', width: 180},
    {field: 'captureTime', headerName: 'Capture Time', width: 180},
    {field: 'reasonForCaptured', headerName: 'Reason For Captured', width: 180},
    {field: 'captureCardChannel', headerName: 'Card Type', width: 180},
    {field: 'cifNumber', headerName: 'CIF Number', width: 180},
    {
      field: 'timeline',
      headerName: 'Timeline',
      width: 100,
      renderCell: (params) => {
        console.log(params, 'parmss')
        return (
          <Link
            to={`${STRINGS.ROUTES.ADMINISTRATOR.CARDS_TIMELINE}/${params.id}`}
            className='btn btn-primary btn-sm'
            state={{data: params.row}}
          >
            Timeline
          </Link>
        )
      },
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 100,
      renderCell: (params) => {
        const notes = params.value
        return (
          <i
            class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'white',
              fontSize: '24px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            title={notes}
            aria-hidden='true'
          />
        )
      },
    },
  ]
  const tobehotColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'remainingDays', headerName: 'Remaining Days', width: 80},
    {field: 'aging', headerName: 'Aging', width: 80},
    {
      field: 'action',
      headerName: 'Action',
      width: 220,
      renderCell: (params) => {
        return (
          <>
            <td>
              <a target='_blank' className='btn btn-light btn-sm'>
                Contact{' '}
                <span>
                  <b>"Call Center"</b>
                </span>
              </a>
            </td>
          </>
        )
      },
    },
    {field: 'processStatus', headerName: 'Process Status', width: 180},

    {
      field: 'captureCardStatus',
      headerName: 'Card Status',
      width: 150,
      renderCell: (params) => {
        const status = params.value
        let badgeClass

        if (status === 'Warm') {
          badgeClass = 'badge-warning'
        } else if (status !== 'Active') {
          badgeClass = 'badge-danger'
        } else {
          badgeClass = 'badge-success'
        }

        return (
          <td>
            <span className={`badge ${badgeClass}`}>{status}</span>
          </td>
        )
      },
    },
    {
      field: 'destructionReason',
      headerName: 'Destruction Reason',
      width: 180,
      renderCell: (params) => {
        const status = params.value
        return (
          <td>
            {`${
              status == 'Hot'
                ? 'Card Hot'
                : status == 'Active'
                ? 'Customer not Recieved'
                : status.includes('xpire')
                ? 'Expired'
                : 'Anonymous'
            }`}
          </td>
        )
      },
    },
    {field: 'cardExpiry', headerName: 'Expiry', width: 180},
    {
      field: 'cardNumber',
      headerName: 'card Number',
      width: 180,
      renderCell: (params) => {
        // console.log(params)
        return (
          <p
            // onMouseLeave={() => {
            //   // console.log('1', decryptNumberPrint)
            //   setDecryptNumberPrint('')
            // }}
            // onMouseEnter={() => decryptNumber(params.row.id)}
            // class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'black',
              fontSize: '13px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            // title={decryptNumberPrint}
            aria-hidden='true'
          >
            {params.row.cardNumber}{' '}
          </p>
        )
      },
    },
    {field: 'customerName', headerName: 'customer Name', width: 180},
    {field: 'acquirerBank', headerName: 'Acquirer Bank', width: 180},
    {field: 'acquirerBranch', headerName: 'Captured Branch', width: 180},
    {field: 'issuingBank', headerName: 'Issuing Bank', width: 180},
    {field: 'issuingBranch', headerName: 'Issuing Branch', width: 180},
    {field: 'captureDate', headerName: 'Capture Date', width: 180},
    {field: 'captureTime', headerName: 'Capture Time', width: 180},
    {field: 'reasonForCaptured', headerName: 'Reason For Captured', width: 180},
    {field: 'captureCardChannel', headerName: 'Card Type', width: 180},
    {field: 'cifNumber', headerName: 'CIF Number', width: 180},
    {
      field: 'timeline',
      headerName: 'Timeline',
      width: 100,
      renderCell: (params) => {
        console.log(params, 'parmss')
        return (
          <Link
            to={`${STRINGS.ROUTES.ADMINISTRATOR.CARDS_TIMELINE}/${params.id}`}
            className='btn btn-primary btn-sm'
            state={{data: params.row}}
          >
            Timeline
          </Link>
        )
      },
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 100,
      renderCell: (params) => {
        const notes = params.value
        return (
          <i
            class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'white',
              fontSize: '24px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            title={notes}
            aria-hidden='true'
          />
        )
      },
    },
  ]

  const shredColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'remainingDays', headerName: 'Remaining Days', width: 80},
    {field: 'aging', headerName: 'Aging', width: 80},
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <td>
              {tableType != 'cfcDefault' ? (
                !params.row.onApproval ? (
                  <div className='btn-group'>
                    {tableType == 'checker' || tableType == 'management' ? (
                      <button type='button' className='btn btn-light btn-sm'>
                        {/* {params.row.action} */}
                      </button>
                    ) : (
                      <>
                        <Link
                          to={`${STRINGS.ROUTES.BRANCH.SHREDFORM}/${params.row.id}`}
                          type='button'
                          className='btn btn-light btn-sm'
                        >
                          {params.row.action}
                        </Link>

                        <PopupState variant='popper' popupId='demo-popup-popper'>
                          {(popupState) => (
                            <div onClick={() => ForceActionFunction(params.row.activityId)}>
                              <button
                                className='btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split'
                                {...bindToggle(popupState)}
                              ></button>
                              <Popper {...bindPopper(popupState)} transition>
                                {({TransitionProps}) => (
                                  <Fade {...TransitionProps} timeout={350}>
                                    <Paper style={{padding: '5px'}}>
                                      {forceActions &&
                                        forceActions.map((nestedItem, index) => {
                                          return (
                                            <div
                                              data-bs-toggle='modal'
                                              data-bs-target='#cardActive3'
                                              onClick={() => {
                                                console.log('nestedItem', nestedItem)
                                                setNextActivityId(nestedItem.forceActivityId)
                                                setCardId(params.row)
                                                setShowHoldApproval(true)
                                              }}
                                              className='dropdown-item'
                                            >
                                              {nestedItem.forceActivity}
                                            </div>
                                          )
                                        })}
                                    </Paper>
                                  </Fade>
                                )}
                              </Popper>
                            </div>
                          )}
                        </PopupState>
                      </>
                    )}
                  </div>
                ) : (
                  <div className='btn-group'>
                    <div type='button' className='btn btn-light btn-sm'>
                      {params.row.action}
                    </div>
                  </div>
                )
              ) : (
                <div className='btn-group'>
                  <div type='button' className='btn btn-light btn-sm'>
                    {params.row.action}
                  </div>
                </div>
              )}
            </td>
          </>
        )
      },
    },
    {field: 'processStatus', headerName: 'Process Status', width: 180},

    {
      field: 'captureCardStatus',
      headerName: 'Card Status',
      width: 150,
      renderCell: (params) => {
        const status = params.value
        let badgeClass

        if (status === 'Warm') {
          badgeClass = 'badge-warning'
        } else if (status !== 'Active') {
          badgeClass = 'badge-danger'
        } else {
          badgeClass = 'badge-success'
        }

        return (
          <td>
            <span className={`badge ${badgeClass}`}>{status}</span>
          </td>
        )
      },
    },
    {
      field: 'destructionReason',
      headerName: 'Destruction Reason',
      width: 180,
      renderCell: (params) => {
        const status = params.value
        return (
          <td>
            {`${
              status == 'Hot'
                ? 'Card Hot'
                : status == 'Active'
                ? 'Customer not Recieved'
                : status.includes('xpire')
                ? 'Expired'
                : 'Anonymous'
            }`}
          </td>
        )
      },
    },
    {field: 'cardExpiry', headerName: 'Expiry', width: 180},
    {
      field: 'cardNumber',
      headerName: 'card Number',
      width: 180,
      renderCell: (params) => {
        // console.log(params)
        return (
          <p
            // onMouseLeave={() => {
            //   // console.log('1', decryptNumberPrint)
            //   setDecryptNumberPrint('')
            // }}
            // onMouseEnter={() => decryptNumber(params.row.id)}
            // class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'black',
              fontSize: '13px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            // title={decryptNumberPrint}
            aria-hidden='true'
          >
            {params.row.cardNumber}{' '}
          </p>
        )
      },
    },
    {field: 'customerName', headerName: 'customer Name', width: 180},
    {field: 'acquirerBank', headerName: 'Acquirer Bank', width: 180},
    {field: 'acquirerBranch', headerName: 'Captured Branch', width: 180},
    {field: 'issuingBank', headerName: 'Issuing Bank', width: 180},
    {field: 'issuingBranch', headerName: 'Issuing Branch', width: 180},
    {field: 'captureDate', headerName: 'Capture Date', width: 180},
    {field: 'captureTime', headerName: 'Capture Time', width: 180},
    {field: 'reasonForCaptured', headerName: 'Reason For Captured', width: 180},
    {field: 'captureCardChannel', headerName: 'Card Type', width: 180},
    {field: 'cifNumber', headerName: 'CIF Number', width: 180},
    {
      field: 'timeline',
      headerName: 'Timeline',
      width: 100,
      renderCell: (params) => {
        console.log(params, 'parmss')
        return (
          <Link
            to={`${STRINGS.ROUTES.ADMINISTRATOR.CARDS_TIMELINE}/${params.id}`}
            className='btn btn-primary btn-sm'
            state={{data: params.row}}
          >
            Timeline
          </Link>
        )
      },
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 100,
      renderCell: (params) => {
        const notes = params.value
        return (
          <i
            class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'white',
              fontSize: '24px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            title={notes}
            aria-hidden='true'
          />
        )
      },
    },
  ]

  const shredHistoryColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'remainingDays', headerName: 'Remaining Days', width: 80},
    {field: 'aging', headerName: 'Aging', width: 80},
    {field: 'processStatus', headerName: 'Process Status', width: 140},
    {
      field: 'captureCardStatus',
      headerName: 'Card Status',
      width: 150,
      renderCell: (params) => {
        const status = params.value
        let badgeClass

        if (status === 'Warm') {
          badgeClass = 'badge-warning'
        } else if (status !== 'Active') {
          badgeClass = 'badge-danger'
        } else {
          badgeClass = 'badge-success'
        }

        return (
          <td>
            <span className={`badge ${badgeClass}`}>{status}</span>
          </td>
        )
      },
    },
    {
      field: 'destructionReason',
      headerName: 'Destruction Reason',
      width: 180,
      renderCell: (params) => {
        const status = params.value
        return (
          <td>
            {`${
              status == 'Hot'
                ? 'Card Hot'
                : status == 'Active'
                ? 'Customer not Recieved'
                : 'Expired'
            }`}
          </td>
        )
      },
    },
    {field: 'cardExpiry', headerName: 'Expiry', width: 180},
    {
      field: 'cardNumber',
      headerName: 'card Number',
      width: 180,
      renderCell: (params) => {
        // console.log(params)
        return (
          <p
            // onMouseLeave={() => {
            //   // console.log('1', decryptNumberPrint)
            //   setDecryptNumberPrint('')
            // }}
            // onMouseEnter={() => decryptNumber(params.row.id)}
            // class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'black',
              fontSize: '13px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            // title={decryptNumberPrint}
            aria-hidden='true'
          >
            {params.row.cardNumber}{' '}
          </p>
        )
      },
    },
    {field: 'customerName', headerName: 'customer Name', width: 180},
    {field: 'acquirerBank', headerName: 'Acquirer Bank', width: 180},
    {field: 'acquirerBranch', headerName: 'Captured Branch', width: 180},
    {field: 'issuingBank', headerName: 'Issuing Bank', width: 180},
    {field: 'issuingBranch', headerName: 'Issuing Branch', width: 180},
    {field: 'captureDate', headerName: 'Capture Date', width: 180},
    {field: 'captureTime', headerName: 'Capture Time', width: 180},
    {field: 'reasonForCaptured', headerName: 'Reason For Captured', width: 180},
    {field: 'captureCardChannel', headerName: 'Card Type', width: 180},
    {field: 'cifNumber', headerName: 'CIF Number', width: 180},
    {
      field: 'timeline',
      headerName: 'Timeline',
      width: 100,
      renderCell: (params) => {
        console.log(params, 'parmss')
        return (
          <Link
            to={`${STRINGS.ROUTES.ADMINISTRATOR.CARDS_TIMELINE}/${params.id}`}
            className='btn btn-primary btn-sm'
            state={{data: params.row}}
          >
            Timeline
          </Link>
        )
      },
    },
    {
      field: 'attachements',
      headerName: 'Attachments',
      width: 150,
      renderCell: (row) => {
        // const {row} = params
        const attachments = row.row.attachements?.$values

        return (
          <Button
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => downloadAttachments(attachments)}
            rel='noopener noreferrer'
            disabled={attachments <= 0}
          >
            Attachments
          </Button>
        )
      },
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 100,
      renderCell: (params) => {
        const notes = params.value
        return (
          <i
            class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'white',
              fontSize: '24px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            title={notes}
            aria-hidden='true'
          />
        )
      },
    },
  ]
  const deliveryHistoryColumns = [
    {field: 'SNo', headerName: 'S.no', width: 150, border: '2px solid black'},
    {field: 'remainingDays', headerName: 'Remaining Days', width: 80},
    {field: 'aging', headerName: 'Aging', width: 80},
    {field: 'processStatus', headerName: 'Process Status', width: 140},
    {
      field: 'captureCardStatus',
      headerName: 'Card Status',
      width: 150,
      renderCell: (params) => {
        const status = params.value
        let badgeClass

        if (status === 'Warm') {
          badgeClass = 'badge-warning'
        } else if (status !== 'Active') {
          badgeClass = 'badge-danger'
        } else {
          badgeClass = 'badge-success'
        }

        return (
          <td>
            <span className={`badge ${badgeClass}`}>{status}</span>
          </td>
        )
      },
    },

    {field: 'cardExpiry', headerName: 'Expiry', width: 180},
    {
      field: 'cardNumber',
      headerName: 'card Number',
      width: 180,
      renderCell: (params) => {
        // console.log(params)
        return (
          <p
            // onMouseLeave={() => {
            //   // console.log('1', decryptNumberPrint)
            //   setDecryptNumberPrint('')
            // }}
            // onMouseEnter={() => decryptNumber(params.row.id)}
            // class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'black',
              fontSize: '13px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            // title={decryptNumberPrint}
            aria-hidden='true'
          >
            {params.row.cardNumber}{' '}
          </p>
        )
      },
    },
    {field: 'customerName', headerName: 'customer Name', width: 180},
    {field: 'acquirerBank', headerName: 'Acquirer Bank', width: 180},
    {field: 'acquirerBranch', headerName: 'Captured Branch', width: 180},
    {field: 'issuingBank', headerName: 'Issuing Bank', width: 180},
    {field: 'issuingBranch', headerName: 'Issuing Branch', width: 180},
    {field: 'captureDate', headerName: 'Capture Date', width: 180},
    {field: 'captureTime', headerName: 'Capture Time', width: 180},
    {field: 'reasonForCaptured', headerName: 'Reason For Captured', width: 180},
    {field: 'captureCardChannel', headerName: 'Card Type', width: 180},
    {field: 'cifNumber', headerName: 'CIF Number', width: 180},
    {
      field: 'timeline',
      headerName: 'Timeline',
      width: 100,
      renderCell: (params) => {
        console.log(params, 'parmss')
        return (
          <Link
            to={`${STRINGS.ROUTES.ADMINISTRATOR.CARDS_TIMELINE}/${params.id}`}
            className='btn btn-primary btn-sm'
            state={{data: params.row}}
          >
            Timeline
          </Link>
        )
      },
    },
    {
      field: 'attachements',
      headerName: 'Attachments',
      width: 150,
      renderCell: (row) => {
        // const {row} = params
        const attachments = row.row.attachements?.$values

        return (
          <Button
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => downloadAttachments(attachments)}
            rel='noopener noreferrer'
            disabled={attachments <= 0}
          >
            Attachments
          </Button>
        )
      },
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 100,
      renderCell: (params) => {
        const notes = params.value
        return (
          <i
            class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'white',
              fontSize: '24px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            title={notes}
            aria-hidden='true'
          />
        )
      },
    },
  ]
  const dispatchHistoryColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'remainingDays', headerName: 'Remaining Days', width: 80},
    {field: 'aging', headerName: 'Aging', width: 80},
    {field: 'processStatus', headerName: 'Process Status', width: 140},
    {
      field: 'captureCardStatus',
      headerName: 'Card Status',
      width: 150,
      renderCell: (params) => {
        const status = params.value
        let badgeClass

        if (status === 'Warm') {
          badgeClass = 'badge-warning'
        } else if (status !== 'Active') {
          badgeClass = 'badge-danger'
        } else {
          badgeClass = 'badge-success'
        }

        return (
          <td>
            <span className={`badge ${badgeClass}`}>{status}</span>
          </td>
        )
      },
    },

    {field: 'cardExpiry', headerName: 'Expiry', width: 180},
    {
      field: 'cardNumber',
      headerName: 'card Number',
      width: 180,
      renderCell: (params) => {
        // console.log(params)
        return (
          <p
            // onMouseLeave={() => {
            //   // console.log('1', decryptNumberPrint)
            //   setDecryptNumberPrint('')
            // }}
            // onMouseEnter={() => decryptNumber(params.row.id)}
            // class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'black',
              fontSize: '13px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            // title={decryptNumberPrint}
            aria-hidden='true'
          >
            {params.row.cardNumber}{' '}
          </p>
        )
      },
    },
    {field: 'customerName', headerName: 'customer Name', width: 180},
    {field: 'acquirerBank', headerName: 'Acquirer Bank', width: 180},
    {field: 'acquirerBranch', headerName: 'Captured Branch', width: 180},
    {field: 'issuingBank', headerName: 'Issuing Bank', width: 180},
    {field: 'issuingBranch', headerName: 'Issuing Branch', width: 180},
    {field: 'captureDate', headerName: 'Capture Date', width: 180},
    {field: 'captureTime', headerName: 'Capture Time', width: 180},
    {field: 'reasonForCaptured', headerName: 'Reason For Captured', width: 180},
    {field: 'captureCardChannel', headerName: 'Card Type', width: 180},
    {field: 'cifNumber', headerName: 'CIF Number', width: 180},
    {
      field: 'timeline',
      headerName: 'Timeline',
      width: 100,
      renderCell: (params) => {
        console.log(params, 'parmss')
        return (
          <Link
            to={`${STRINGS.ROUTES.ADMINISTRATOR.CARDS_TIMELINE}/${params.id}`}
            className='btn btn-primary btn-sm'
            state={{data: params.row}}
          >
            Timeline
          </Link>
        )
      },
    },
    {
      field: 'attachements',
      headerName: 'Attachments',
      width: 150,
      renderCell: (row) => {
        // const {row} = params
        const attachments = row.row.attachements?.$values

        return (
          <Button
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => downloadAttachments(attachments)}
            rel='noopener noreferrer'
            disabled={attachments <= 0}
          >
            Attachments
          </Button>
        )
      },
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 100,
      renderCell: (params) => {
        const notes = params.value
        return (
          <i
            class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'white',
              fontSize: '24px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            title={notes}
            aria-hidden='true'
          />
        )
      },
    },
  ]
  const suspiciousHistoryColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'remainingDays', headerName: 'Remaining Days', width: 80},
    {field: 'aging', headerName: 'Aging', width: 80},
    {field: 'processStatus', headerName: 'Process Status', width: 140},
    {
      field: 'captureCardStatus',
      headerName: 'Card Status',
      width: 150,
      renderCell: (params) => {
        const status = params.value
        let badgeClass

        if (status === 'Warm') {
          badgeClass = 'badge-warning'
        } else if (status !== 'Active') {
          badgeClass = 'badge-danger'
        } else {
          badgeClass = 'badge-success'
        }

        return (
          <td>
            <span className={`badge ${badgeClass}`}>{status}</span>
          </td>
        )
      },
    },

    {field: 'cardExpiry', headerName: 'Expiry', width: 180},
    {
      field: 'cardNumber',
      headerName: 'card Number',
      width: 180,
      renderCell: (params) => {
        // console.log(params)
        return (
          <p
            // onMouseLeave={() => {
            //   // console.log('1', decryptNumberPrint)
            //   setDecryptNumberPrint('')
            // }}
            // onMouseEnter={() => decryptNumber(params.row.id)}
            // class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'black',
              fontSize: '13px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            // title={decryptNumberPrint}
            aria-hidden='true'
          >
            {params.row.cardNumber}{' '}
          </p>
        )
      },
    },
    {field: 'customerName', headerName: 'customer Name', width: 180},
    {field: 'acquirerBank', headerName: 'Acquirer Bank', width: 180},
    {field: 'acquirerBranch', headerName: 'Captured Branch', width: 180},
    {field: 'issuingBank', headerName: 'Issuing Bank', width: 180},
    {field: 'issuingBranch', headerName: 'Issuing Branch', width: 180},
    {field: 'captureDate', headerName: 'Capture Date', width: 180},
    {field: 'captureTime', headerName: 'Capture Time', width: 180},
    {field: 'reasonForCaptured', headerName: 'Reason For Captured', width: 180},
    {field: 'captureCardChannel', headerName: 'Card Type', width: 180},
    {field: 'cifNumber', headerName: 'CIF Number', width: 180},
    {
      field: 'timeline',
      headerName: 'Timeline',
      width: 100,
      renderCell: (params) => {
        console.log(params, 'parmss')
        return (
          <Link
            to={`${STRINGS.ROUTES.ADMINISTRATOR.CARDS_TIMELINE}/${params.id}`}
            className='btn btn-primary btn-sm'
            state={{data: params.row}}
          >
            Timeline
          </Link>
        )
      },
    },
    {
      field: 'attachements',
      headerName: 'Attachments',
      width: 150,
      renderCell: (row) => {
        // const {row} = params
        const attachments = row.row.attachements?.$values

        return (
          <Button
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => downloadAttachments(attachments)}
            rel='noopener noreferrer'
            disabled={attachments <= 0}
          >
            Attachments
          </Button>
        )
      },
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 100,
      renderCell: (params) => {
        const notes = params.value
        return (
          <i
            class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'white',
              fontSize: '24px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            title={notes}
            aria-hidden='true'
          />
        )
      },
    },
  ]
  const tobehotSambaPhoneColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'remainingDays', headerName: 'Remaining Days', width: 80},
    {field: 'aging', headerName: 'Aging', width: 80},
    {
      field: 'action',
      headerName: 'Action',
      width: 220,
      renderCell: (params) => {
        return (
          <>
            <td>
              <div>
                <button
                  data-bs-toggle='modal'
                  data-bs-target='#cardActive'
                  className='btn btn-sm'
                  style={{backgroundColor: 'white'}}
                  onClick={() => {
                    setCardId(params.row)
                    setShowHold2(true)
                  }}
                >
                  Active
                </button>
              </div>
            </td>
          </>
        )
      },
    },
    {
      field: 'captureCardStatus',
      headerName: 'Card Status',
      width: 150,
      renderCell: (params) => {
        const status = params.value
        let badgeClass

        if (status === 'Warm') {
          badgeClass = 'badge-warning'
        } else if (status !== 'Active') {
          badgeClass = 'badge-danger'
        } else {
          badgeClass = 'badge-success'
        }

        return (
          <td>
            <span className={`badge ${badgeClass}`}>{status}</span>
          </td>
        )
      },
    },
    {
      field: 'destructionReason',
      headerName: 'Destruction Reason',
      width: 180,
      renderCell: (params) => {
        const status = params.value
        return (
          <td>
            {`${status == 'Hot' ? 'Card Hot' : status == 'Active' ? 'Customer not Recieved' : ''}`}
          </td>
        )
      },
    },
    {field: 'cardExpiry', headerName: 'Expiry', width: 180},
    {
      field: 'cardNumber',
      headerName: 'card Number',
      width: 180,
      renderCell: (params) => {
        // console.log(params)
        return (
          <p
            // onMouseLeave={() => {
            //   // console.log('1', decryptNumberPrint)
            //   setDecryptNumberPrint('')
            // }}
            // onMouseEnter={() => decryptNumber(params.row.id)}
            // class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'black',
              fontSize: '13px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            // title={decryptNumberPrint}
            aria-hidden='true'
          >
            {params.row.cardNumber}{' '}
          </p>
        )
      },
    },
    {field: 'customerName', headerName: 'customer Name', width: 180},
    {field: 'acquirerBank', headerName: 'Acquirer Bank', width: 180},
    {field: 'acquirerBranch', headerName: 'Captured Branch', width: 180},
    {field: 'issuingBank', headerName: 'Issuing Bank', width: 180},
    {field: 'issuingBranch', headerName: 'Issuing Branch', width: 180},
    {field: 'captureDate', headerName: 'Capture Date', width: 180},
    {field: 'captureTime', headerName: 'Capture Time', width: 180},
    {field: 'reasonForCaptured', headerName: 'Reason For Captured', width: 180},
    {field: 'captureCardChannel', headerName: 'Card Type', width: 180},
    {field: 'cifNumber', headerName: 'CIF Number', width: 180},
    {
      field: 'timeline',
      headerName: 'Timeline',
      width: 100,
      renderCell: (params) => {
        console.log(params, 'parmss')
        return (
          <Link
            to={`${STRINGS.ROUTES.ADMINISTRATOR.CARDS_TIMELINE}/${params.id}`}
            className='btn btn-primary btn-sm'
            state={{data: params.row}}
          >
            Timeline
          </Link>
        )
      },
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 100,
      renderCell: (params) => {
        const notes = params.value
        return (
          <i
            class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'white',
              fontSize: '24px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            title={notes}
            aria-hidden='true'
          />
        )
      },
    },
  ]
  const sambaPhoneHistoryColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'remainingDays', headerName: 'Remaining Days', width: 80},
    {field: 'aging', headerName: 'Aging', width: 80},
    // {
    //   field: 'action',
    //   headerName: 'Action',
    //   width: 220,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <td>
    //           <div>
    //             <button
    //               data-bs-toggle='modal'
    //               data-bs-target='#exampleModal'
    //               className='btn btn-sm'
    //               style={{backgroundColor: 'white'}}
    //               onClick={() => {
    //                 setCardId(params.row)
    //                 setShowHold(true)
    //               }}
    //             >
    //               Active
    //             </button>
    //           </div>
    //         </td>
    //       </>
    //     )
    //   },
    // },
    {
      field: 'captureCardStatus',
      headerName: 'Card Status',
      width: 150,
      renderCell: (params) => {
        const status = params.value
        let badgeClass

        if (status === 'Warm') {
          badgeClass = 'badge-warning'
        } else if (status !== 'Active') {
          badgeClass = 'badge-danger'
        } else {
          badgeClass = 'badge-success'
        }

        return (
          <td>
            <span className={`badge ${badgeClass}`}>{status}</span>
          </td>
        )
      },
    },
    {
      field: 'destructionReason',
      headerName: 'Destruction Reason',
      width: 180,
      renderCell: (params) => {
        const status = params.value
        return (
          <td>
            {`${status == 'Hot' ? 'Card Hot' : status == 'Active' ? 'Customer not Recieved' : ''}`}
          </td>
        )
      },
    },
    {field: 'cardExpiry', headerName: 'Expiry', width: 180},
    {
      field: 'cardNumber',
      headerName: 'card Number',
      width: 180,
      renderCell: (params) => {
        // console.log(params)
        return (
          <p
            // onMouseLeave={() => {
            //   // console.log('1', decryptNumberPrint)
            //   setDecryptNumberPrint('')
            // }}
            // onMouseEnter={() => decryptNumber(params.row.id)}
            // class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'black',
              fontSize: '13px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            // title={decryptNumberPrint}
            aria-hidden='true'
          >
            {params.row.cardNumber}{' '}
          </p>
        )
      },
    },
    {field: 'customerName', headerName: 'customer Name', width: 180},
    {field: 'acquirerBank', headerName: 'Acquirer Bank', width: 180},
    {field: 'acquirerBranch', headerName: 'Captured Branch', width: 180},
    {field: 'issuingBank', headerName: 'Issuing Bank', width: 180},
    {field: 'issuingBranch', headerName: 'Issuing Branch', width: 180},
    {field: 'captureDate', headerName: 'Capture Date', width: 180},
    {field: 'captureTime', headerName: 'Capture Time', width: 180},
    {field: 'reasonForCaptured', headerName: 'Reason For Captured', width: 180},
    {field: 'captureCardChannel', headerName: 'Card Type', width: 180},
    {field: 'cifNumber', headerName: 'CIF Number', width: 180},
    {
      field: 'timeline',
      headerName: 'Timeline',
      width: 100,
      renderCell: (params) => {
        console.log(params, 'parmss')
        return (
          <Link
            to={`${STRINGS.ROUTES.ADMINISTRATOR.CARDS_TIMELINE}/${params.id}`}
            className='btn btn-primary btn-sm'
            state={{data: params.row}}
          >
            Timeline
          </Link>
        )
      },
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 100,
      renderCell: (params) => {
        const notes = params.value
        return (
          <i
            class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'white',
              fontSize: '24px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            title={notes}
            aria-hidden='true'
          />
        )
      },
    },
  ]
  //DATA TYPE
  let tableRows = []

  if (type) {
    data?.forEach((element, index) => {
      tableRows.push({
        id: element.id,
        SNo: index + 1,
        remainingDaysTransit: element.tatRemainingDuration,
        remainingDays: element.remainingDays,
        aging: element.aging,
        captureCardStatus: element.captureCardStatus,
        destructionReason: element.captureCardStatus,
        cardExpiry: element.cardExpiry,
        capturedBranch: element.capturedBranch,
        cardNumber:
          element.cardNumber.length < 16
            ? element.cardNumber
            : element.cardNumber.substring(0, 4) +
              '*******' +
              element.cardNumber.substring(element.cardNumber.length - 4),
        // cardNumber: element.cardNumber,
        processStatus: element.processStatus,
        customerName: element.customerName,
        action: element.nextActivity,
        activityId: element.nextActivityId,
        onApproval: element.onApproval,
        onHold: element.onHold,
        // activityId: element.nextActivityId,
        issuingBank: `${
          element.cardOwnerBank
            ? element.cardOwnerBank
            : element.nativeBranchBank
            ? element.nativeBranchBank
            : element.organization
        }`,
        issuingBranch: element.cardBranch,
        acquirerBank: `${
          element.acquiredBranch ? element.capturedBranchBank : element.cardAcquirerBank
        }`,
        acquirerBranch: `${
          element.acquiredBranch ? element.acquiredBranch : element.cardAcquirerBankBranch
        }`,
        captureDate: `${convertISODate(element.capturedDate)}`,
        captureTime: `${convertISOTime(element.capturedDate)}`,
        reasonForCaptured:
          element.captureCardStatus == 'Hot'
            ? 'Card Hot'
            : element.captureCardStatus.includes('xpire')
            ? 'Expired'
            : 'Anonymous',
        captureCardChannel: element.captureCardChannel,
        cifNumber: element.cifNumber,
        notes: element.notes,
        attachements: element.attachements,
        courierCompany: element.courierCompany,
      })
    })
  }

  return (
    <div style={{height: '70vh', width: '100%'}}>
      {type == 'shred' && (
        <DataGrid
          // autoHeight
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={shredColumns}
          showCellRightBorder={true}
        />
      )}
      {type == 'shredHistory' && (
        <DataGrid
          // autoHeight
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={shredHistoryColumns}
          showCellRightBorder={true}
        />
      )}
      {type == 'deliveryHistory' && (
        <DataGrid
          // autoHeight
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={deliveryHistoryColumns}
          showCellRightBorder={true}
        />
      )}
      {type == 'dispatchHistory' && (
        <DataGrid
          // autoHeight
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={dispatchHistoryColumns}
          showCellRightBorder={true}
        />
      )}
      {type == 'suspiciousHistory' && (
        <DataGrid
          // autoHeight
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={suspiciousHistoryColumns}
          showCellRightBorder={true}
        />
      )}

      {type == 'tobehotSambaPhone' && (
        <DataGrid
          // autoHeight
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={tobehotSambaPhoneColumns}
          showCellRightBorder={true}
        />
      )}
      {type == 'sambaPhoneHistory' && (
        <DataGrid
          // autoHeight
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={sambaPhoneHistoryColumns}
          showCellRightBorder={true}
        />
      )}

      {type == 'tobehot' && (
        <DataGrid
          // autoHeight
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={tobehotColumns}
          showCellRightBorder={true}
        />
      )}

      {type == 'hold' && (
        <DataGrid
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          getRowClassName={(params) => 'orange-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={holdColumns}
          showCellRightBorder={true}
        />
      )}

      {type == 'delivery' && (
        <DataGrid
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          getRowClassName={(params) => 'yellow-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={deliveryColumns}
          showCellRightBorder={true}
        />
      )}

      {type == 'dispatch' && (
        <DataGrid
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          getRowClassName={(params) => 'blue-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={dispatchColumns}
          showCellRightBorder={true}
        />
      )}

      {type == 'transitIn' && (
        <DataGrid
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          getRowClassName={(params) => 'transitIn-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={transitInColumns}
          showCellRightBorder={true}
        />
      )}

      {type == 'transitOut' && (
        <DataGrid
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          getRowClassName={(params) => 'transitOut-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={transitOutColumns}
          showCellRightBorder={true}
        />
      )}

      {type == 'suspicious' && (
        <DataGrid
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          getRowClassName={(params) => 'suspicious-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={suspiciousColumns}
          showCellRightBorder={true}
        />
      )}
      {type == 'forApproval' && (
        <DataGrid
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={forApprovalColumns}
          showCellRightBorder={true}
        />
      )}

      <CardSendForApproval
        token={user.api_token}
        nextActivityId={nextActivityId}
        cardId={cardId}
        show={showHoldApproval}
        close={() => setShowHoldApproval(false)}
      />

      <CardHold
        token={user.api_token}
        cardId={cardId}
        show={showHold}
        close={() => setShowHold(false)}
      />

      <CardActive
        token={user.api_token}
        cardId={cardId}
        show={showHold2}
        close={() => setShowHold2(false)}
      />

      <CardFromBranch
        courierCompanyName={courierCompanyName}
        type={tableType ? tableType : 'branch'}
        cardId={cardId}
        branchId={current_id}
        // Refresh={()=>Refresh(refreshData)}
        show
        {...showCardFromBranch}
        close={() => setShowCardFromBranch(false)}
      />
    </div>
  )
}

export default TablesBranch
